import { environment } from 'src/environments/environment';

export function getUrlApiByDomain(): string {
  let url = '';

  switch (window.location.hostname) {
    case 'credenciado.valesaudepet.com.br':
      url = environment.urlApiPet;
      break;
    case 'dev-credenciado.valesaudepet.com.br':
      url = environment.urlApiPet;
      break;
    case 'credenciado.valesaudesempre.com.br':
      url = environment.urlApi;
      break;
    default:
      url = environment.urlApi;
  }

  return url;
}
