import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../../utils/error';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.state';
import * as UserActions from '../../store/modules/user/actions';
import * as UnitActions from '../../store/modules/unit/actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  refreshing = false;

  constructor(
    private store: Store<AppState>,
    private auth: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  /**
   * @description
   * Remove stored information from NGRX and redirects the user
   * to sign in.
   */
  async cleanAuth(): Promise<any> {
    this.store.dispatch(new UserActions.RemoveUser());
    this.store.dispatch(new UnitActions.RemoveUnit());
    await this.router.navigate(['/']);
    this.refreshing = false;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        const { status } = err;

        const isInLoginRoute = req.url.includes('/login');

        if (status === 401 && !this.refreshing && !isInLoginRoute) {
          this.refreshing = true;

          this.auth.refresh().subscribe(
            (res) => {
              const { token, user, merchant, units, settings } = res.data;

              this.store.dispatch(
                new UserActions.UpdateUser({
                  token,
                  user,
                  merchant,
                  units,
                  settings,
                  email: this.userService.getUserProps().email,
                })
              );
              this.store.dispatch(new UserActions.AddUserToken(res.data.token));

              location.reload();
            },
            async () => {
              await this.cleanAuth();
            }
          );
        }

        return throwError(handleError(err));
      })
    );
  }
}
