import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type RouteDataProps =
  | Data
  | { screen_name: string; step: string; key?: string; stepSuffix?: string };

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  current: RouteDataProps | undefined;

  current$: BehaviorSubject<RouteDataProps | undefined> =
    new BehaviorSubject<any>(undefined);

  constructor(private router: Router, private route: ActivatedRoute) {}

  /**
   * @description
   * Emite o campo `data` de uma rota sempre quando a rota da aplicação muda.
   *
   * Realizamos um loop enquanto existem rotas filhas, pois é possível
   * que as rotas sejam aninhadas.
   *
   * @param callback Função que será invocada com o parâmetro `data` da rota.
   */
  getRouteDataOnRouteChange(callback: (data: any) => void): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.route.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route?.snapshot.data;
        })
      )
      .subscribe((res) => {
        this.current = res;
        this.current$.next(res);
        callback(res);
      });
  }
}
