import * as UnitActions from './actions';
import { IUnit } from '../../../models/unit/unit';

const INITIAL_STATE: IUnit = {
  company_name: '',
  document_number: '',
  id: '',
  trading_name: '',
};

export function unitReducer(
  state: IUnit = INITIAL_STATE,
  action: UnitActions.Actions
): IUnit {
  switch (action.type) {
    case UnitActions.UNIT_ADD:
      return (state = action.payload);
    case UnitActions.UNIT_REMOVE:
      return (state = {
        company_name: '',
        document_number: '',
        id: '',
        trading_name: '',
      });
    default:
      return state;
  }
}
