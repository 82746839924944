import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouteService } from '../route/route.service';
import { ThemeService } from '../theme/theme.service';
import {
  EcommerceBrand,
  EcommerceCurrency,
  EcommerceEvent,
  EcommerceItem,
  EcommerceParams,
  EcommerceParamsPayment,
  PageArea,
  PageCategory,
  PageSegment,
  PageView,
  PageViewProps,
} from './models';
import { DeviceService } from '../device/device.service';
import { UserService } from '../user/user.service';
import slugify from '../../utils/strings/slugify/slugify';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const datalayer = window.dataLayer || [];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    public themeService: ThemeService,
    private routeService: RouteService,
    @Inject('Window') public window: any,
    private deviceService: DeviceService,
    private userService: UserService,
  ) {
  }

  get appInfo(): { app: string; version: string } {
    return {
      app: this.themeService.isPet ? 'VSPet' : 'VSS',
      version: environment.version,
    };
  }

  getPageInfo(): {
    page_location: string;
    page_path: string;
    page_search: string;
  } {
    const {
      href: page_location,
      pathname: page_path,
      search: page_search,
    } = this.window.location || {};

    return {
      page_location,
      page_path,
      page_search,
    };
  }

  sendPageView = (pageViewProps: PageView) => {
    const { page_path = '' } = pageViewProps || {};

    const pageParams = this.getPageInfo();
    const userParams = this.userService.getUserProps();

    const params: PageViewProps = {
      page: {
        page_path: page_path || pageParams.page_path + pageParams.page_search,
        environment: this.deviceService.getEnvironment(),
        area: PageArea.B2C,
        segment: PageSegment.Ecommerce,
        category: PageCategory.VSS,
        platform: this.deviceService.getPlatform(),
      },
      user: {
        id: userParams.id,
        email: userParams.email,
        isLoggedin: !!userParams.email,
        companyName: slugify(userParams.company.name),
        companyId: userParams.company.id,
        cnpj: userParams.company.document,
      },
    };

    if (!params.page.page_path) {
      return;
    }

    this.logEvent('page_view', params);
  };

  sendInteraction = (name = '', params: any) => {
    const pageParams = this.getPageInfo();

    if (name) {
      this.logEvent(name, { ...params, ...pageParams });
    }
  };

  initScreenViewRouting(): void {
    this.routeService.getRouteDataOnRouteChange(this.sendPageView);
  }

  logAppVersion(): void {
    const params = this.appInfo;

    this.logEvent('version', params);
  }

  init(): void {
    this.initScreenViewRouting();

    this.logAppVersion();
  }

  logEvent(eventName: string, params: { [key: string]: any }): void {
    const { app, version } = this.appInfo;

    if (params.extras) {
      params = this.addExtrasToObject(params);
    }

    datalayer.push({
      event: eventName,
      ...params,
      app,
      version,
    });
  }

  addExtrasToObject(params: { [key: string]: any }): { [key: string]: any } {
    const extras = params.extras;

    for (const extrasKey in extras) {
      if (extras.hasOwnProperty(extrasKey)) {
        params[extrasKey] = extras[extrasKey];
      }
    }

    delete params.extras;

    return params;
  }

  sendEcommerceEvent(
    name: EcommerceEvent,
    items: EcommerceItem[],
    value: number,
    ecommerce?: EcommerceParamsPayment,
  ): void {
    const params: { ecommerce: EcommerceParams } = {
      ecommerce: {
        currency: EcommerceCurrency.BRL,
        value,
        ...ecommerce,
        items,
      },
    };

    this.logEvent(name, params);
  }

  transformProcedureItemsToAnalyticsItems(items: any[]): {
    analyticsItems: EcommerceItem[];
    total: number;
  } {
    let total = 0;
    const analyticsItems: EcommerceItem[] = [];

    items.forEach((item, index) => {
      const { code, description, price, quantity = 1 } = item;
      const analyticsItem: EcommerceItem = {
        item_id: code,
        item_brand: EcommerceBrand.VSS,
        item_category: 'procedimentos',
        item_name: slugify(description),
        price,
        quantity,
        index: index + 1,
      };

      total += price * quantity;

      analyticsItems.push(analyticsItem);
    });

    return { analyticsItems, total };
  }
}
