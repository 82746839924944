import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from './services/settings/settings.service';
import { ThemeService } from './services/theme/theme.service';
import { ModalWelcomeComponent } from './components/modals/modal-welcome/modal-welcome.component';
import { AnalyticsService } from './services/analytics/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private settings: SettingsService,
    private themeService: ThemeService,
    public analyticsService: AnalyticsService,
    @Inject('Window') private window: any
  ) {}

  ngOnInit(): void {
    this.analyticsService.init();

    this.getSettings();
    this.handleScroll();

    if (this.themeService.isPet) {
      this.setPetFavIcon();
      this.setPetTitle();
    } else {
      this.presentWelcome();
    }
  }

  setPetTitle(): void {
    const $title = document.getElementsByTagName('title')[0];
    $title.innerText = 'Vale Saúde Pet';
  }

  setPetFavIcon(): void {
    const $favIcon: HTMLLinkElement | null =
      document.querySelector('[rel="icon"]');
    if ($favIcon) {
      $favIcon.href = '/assets/img/pet/favicon.ico';
    }
  }

  getSettings(): void {
    this.settings.get().subscribe();
  }

  handleScroll(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      this.window.scrollTo(0, 0);
    });
  }

  presentWelcome(): void {
    const hasSeenWelcome = localStorage.getItem('welcome');

    if (!hasSeenWelcome) {
      const dialog = this.dialog.open(ModalWelcomeComponent, {
        maxWidth: 620,
        autoFocus: false,
      });

      dialog.afterOpened().subscribe(() => {
        localStorage.setItem('welcome', 'true');
      });
    }
  }
}
