export type PageEnvironmentProps = 'web' | 'mobile';

export enum PageCategory {
  VSS = 'vale-saude',
}

export enum PageArea {
  B2C = 'b2c',
}

export enum PageSegment {
  Ecommerce = 'ecommerce',
}

type PageProps = {
  page_path: string;
  variation?: string;
  geoRegion?: string;
  pageID?: string;
  environment: PageEnvironmentProps;
  area: PageArea;
  segment: PageSegment.Ecommerce;
  category: PageCategory;
  subcategory?: string;
  platform: 'web' | 'android' | 'ios';
};

type UserProps = {
  id: string;
  email: string; // hash
  isLoggedin: boolean;
  companyName: string;
  cnpj: string;
  companyId: string;
};

export type PageViewProps = {
  page: PageProps;
  user: UserProps;
};

export interface PageView {
  page_path: string;
}

export enum EcommerceEvent {
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  SelectItem = 'select_item',
  ViewItemList = 'view_item_list',
}

type EcommerceItemListProps = {
  item_list_id?: string | number;
  item_list_name?: string;
};

export type EcommerceItem = EcommerceItemListProps & {
  item_name: string;
  item_id: string | number;
  price: number;
  item_brand: string;
  item_category: string;
  quantity: number;
  index?: number;
};

export type EcommerceItemPurchase = EcommerceItem & {
  discount: number;
  coupon?: string;
};

export enum EcommercePaymentType {
  Card = 'cartao-vss',
  CreditCard = 'cartao-de-credito',
  Invoice = 'fatura-vivo',
  Bankslip = 'boleto',
  Pix = 'pix',
}

export enum EcommerceCurrency {
  BRL = 'BRL',
}

export type EcommerceParamsPayment = EcommerceItemListProps & {
  transaction_id?: string | number;
  payment_type?: EcommercePaymentType;
};

export type EcommerceParams = EcommerceParamsPayment & {
  currency: EcommerceCurrency.BRL;
  value: number;
  items: EcommerceItem[];
};

export enum EcommerceBrand {
  VSS = 'vale-saude',
}

export enum EcommerceProducts {
  Value = 'venda-por-valor',
  Item = 'venda-por-item',
}
