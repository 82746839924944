import { Injectable } from '@angular/core';

import { ErrorLoggerWrapperService } from './error-logger-wrapper/error-logger-wrapper.service';
import { ErrorLoggerParams, ErrorLoggerUser } from './error-logger.data';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggerService {
  constructor(private errorLoggerWrapperService: ErrorLoggerWrapperService) {}

  generateUserId(user: ErrorLoggerUser): string {
    const {
      user: { id: userId = '' },
      units: [],
    } = user || {};

    const unitId = user.units.length ? user.units[0]?.id : '';

    if (userId && unitId) return `user-id:${userId}/unit-id:${unitId}`;

    throw new Error('Missing userId or unitId');
  }

  setUser(user: ErrorLoggerUser): void {
    const id = this.generateUserId(user);

    this.errorLoggerWrapperService.setUser(id);
  }

  sendException(exception: Error | string, params?: ErrorLoggerParams): void {
    if (!params) return;

    this.errorLoggerWrapperService.captureException(exception, (ev) => {
      const { level = 'error', context, extra = {} } = params;

      ev.severity = level;

      ev.context = context;

      ev.addMetadata('extra', extra);
    });
  }
}
