import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorLoggerService } from 'src/app/services/error-logger/error-logger.service';
import { AppState } from '../../app.state';
import { IUser } from '../../models/user/user';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedGuard  {
  user: IUser = { token: '', user: {}, merchant: {}, units: [], settings: {}, email: '' };

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private errorLoggerService: ErrorLoggerService
  ) {
    store.select('user').subscribe((user) => {
      this.user = user;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { token } = this.user;

    if (!token) {
      return this.router.navigate(['/']);
    }

    this.errorLoggerService.setUser(this.user);
    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { token } = this.user;

    if (!token) {
      return this.router.navigate(['/']);
    }

    this.errorLoggerService.setUser(this.user);
    return true;
  }
}
