import * as forge from 'node-forge';

import { environment } from '../../../environments/environment';

export const encrypt = (data: string): string => {
  if (!data) {
    return data;
  }

  const md = forge.md.sha256.create();
  const value = `${data}${environment.SALT}`;

  md.update(value);

  return md.digest().toHex();
};
