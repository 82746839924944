import { Action } from '@ngrx/store';

export const SELL_ADD = '@sell/add';
export const SELL_REMOVE = '@sell/remove';

export class AddSell implements Action {
  readonly type = SELL_ADD;

  constructor(public payload: any) {
  }
}

export class RemoveSell implements Action {
  readonly type = SELL_REMOVE;

  constructor(public payload: any) {
  }
}

export type Actions = AddSell | RemoveSell;
