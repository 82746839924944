import { HttpErrorResponse } from '@angular/common/http';

export const handleError = (err: HttpErrorResponse): any => {
  // tslint:disable-next-line:prefer-const
  let { fields, message } = err.error.error || err.error;

  if (!fields) {
    fields = err.error.errors;
  }

  return { fields, message };
};

/**
 * @description
 * Inclui um erro da api de um campo em seu respectivo estado.
 */
export const updateFormErrors = (form: any, errors: any) => {
  const { fields } = errors;
  if (fields) {
    for (const k in fields) {
      if (fields.hasOwnProperty(k) && form.controls[k]) {
        form.controls[k].setErrors({ custom: fields[k][0] });
      }
    }

    scrollToFirstError();
  }
};

export function scrollToFirstError(): void {
  setTimeout(() => {
    const els = document.getElementsByClassName(
      'text-control-message'
    ) as HTMLCollectionOf<HTMLElement>;
    const offsetTop = els.length ? els[0].offsetTop : 0;
    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
  }, 300);
}
