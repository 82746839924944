import { Injectable } from '@angular/core';
import { PageEnvironmentProps } from '../analytics/models';

export enum PlatformType {
  Web = 'web',
  Ios = 'ios',
  Android = 'android',
}

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  getPlatform(): PlatformType {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return PlatformType.Web;
    }

    if (/android/i.test(userAgent)) {
      return PlatformType.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return PlatformType.Ios;
    }

    return PlatformType.Web;
  }

  getEnvironment(): PageEnvironmentProps {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return isMobile ? 'mobile' : 'web';
  }
}
