import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  get isPet(): boolean {
    return window.location.hostname.includes('valesaudepet');
  }
}
