import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService, HttpOptions } from '../base/base.service';
import {
  AuthItem,
  AuthItemCreate,
  AuthItemForgot,
  AuthItemPassword,
  AuthItemReset,
  AuthItemUpdate,
} from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as UserActions from '../../store/modules/user/actions';
import * as UnitActions from '../../store/modules/unit/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Router } from '@angular/router';
import { getUrlApiByDomain } from 'src/app/utils/api';

const PATH = 'auth/merchants';
const VERSION = 'v1';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService<
  AuthItem,
  AuthItemCreate,
  AuthItemUpdate
> {
  constructor(
    private readonly httpClient: HttpClient,
    private storeState: Store<AppState>,
    private router: Router
  ) {
    super(httpClient, `${PATH}/login`, VERSION, storeState);
  }

  /**
   * @description
   * Authenticates an user.
   * In case of success all the returned information are stored on NGRX.
   *
   * @param creationBody  User information
   * @param options       Extra options up to you
   */
  create(
    creationBody: AuthItemCreate,
    options: HttpOptions = {}
  ): Observable<AuthItem> {
    return super.create(creationBody, options).pipe(
      map((res: any) => {
        const { token, user, merchant, units, settings } = res.data;

        const email = creationBody.username || '';

        this.store?.dispatch(
          new UserActions.AddUser({
            token,
            user,
            merchant,
            units,
            settings,
            email,
          })
        );

        this.store?.dispatch(new UnitActions.AddUnit({ ...units[0] }));

        return res;
      })
    );
  }

  forgot(data: AuthItemForgot): Observable<any> {
    return this.httpClient.post(
      `${getUrlApiByDomain()}/${VERSION}/${PATH}/forgot-password`,
      data
    );
  }

  reset(data: AuthItemReset): Observable<any> {
    return this.httpClient.put(
      `${getUrlApiByDomain()}/${VERSION}/${PATH}/reset-password`,
      data
    );
  }

  refresh(): Observable<any> {
    return this.httpClient.post(
      `${getUrlApiByDomain()}/${VERSION}/${PATH}/refresh`,
      {}
    );
  }

  redefinePassword(data: AuthItemPassword): Observable<any> {
    return this.httpClient.put(
      `${getUrlApiByDomain()}/${VERSION}/merchants/user/password`,
      data
    );
  }

  logout(): Observable<any> {
    const user = localStorage.user;
    let token;

    if (user) {
      token = JSON.parse(user)?.token;
    }

    this.store?.dispatch(new UserActions.RemoveUser());
    this.store?.dispatch(new UnitActions.RemoveUnit());

    this.router.navigate(['/']);

    return this.httpClient.post(
      `${getUrlApiByDomain()}/${VERSION}/${PATH}/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
