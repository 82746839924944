import * as UserActions from './actions';
import { IUser } from '../../../models/user/user';

const INITIAL_STATE: IUser = {
  token: '',
  user: {},
  merchant: {},
  units: [],
  settings: {},
  email: '',
};

export function userReducer(
  state: IUser = INITIAL_STATE,
  action: UserActions.Actions
): IUser {
  switch (action.type) {
    case UserActions.USER_ADD:
      return (state = action.payload);

    case UserActions.USER_UPDATE:
      return (state = {
        ...state,
        ...action.payload,
      });

    case UserActions.USER_ADD_TOKEN:
      return (state = {
        ...state,
        token: action.payload,
      });

    case UserActions.USER_REMOVE:
      return (state = {
        token: '',
        user: {},
        merchant: {},
        units: [],
        settings: {},
        email: '',
      });

    default:
      return state;
  }
}
