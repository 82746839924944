import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { ModalWelcomeComponent } from './modal-welcome.component';

@NgModule({
  declarations: [ModalWelcomeComponent],
  imports: [CommonModule, MatDialogModule, RouterModule],
  exports: [ModalWelcomeComponent],
})
export class ModalWelcomeModule {}
