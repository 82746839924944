import { Action } from '@ngrx/store';
import { IUnit } from '../../../models/unit/unit';

export const UNIT_ADD = '@unit/add';
export const UNIT_REMOVE = '@unit/remove';

export class AddUnit implements Action {
  readonly type = UNIT_ADD;

  constructor(public payload: IUnit) {
  }
}

export class RemoveUnit implements Action {
  readonly type = UNIT_REMOVE;

  constructor() {
  }
}

export type Actions = AddUnit | RemoveUnit;
