import { Injectable } from '@angular/core';
import { BaseService, HttpOptions } from '../base/base.service';
import { SettingsItem } from './models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as SettingsActions from '../../store/modules/settings/actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

const PATH = 'settings';
const VERSION = 'v1';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService<
  SettingsItem,
  SettingsItem,
  SettingsItem
> {
  constructor(
    private readonly httpClient: HttpClient,
    private storeState: Store<AppState>
  ) {
    super(httpClient, `${PATH}`, VERSION, storeState);
  }

  get(options: HttpOptions = {}): Observable<SettingsItem[]> {
    return super.get(options).pipe(
      map((res: any) => {
        const { data } = res;
        this.store?.dispatch(new SettingsActions.AddSettings(data));
        return res;
      })
    );
  }
}
