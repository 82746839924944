import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggerWrapperService {
  public get captureException() {
    return Bugsnag.notify;
  }

  public get setUser() {
    return Bugsnag.setUser;
  }
}
