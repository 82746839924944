import { Injectable } from '@angular/core';
import { encrypt } from '../../utils/encrypt/encrypt';

type UserProps = {
  id: string;
  email: string;
  company: {
    id: string;
    name: string;
    document: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor() {}

  getUserProps(): UserProps {
    const user = localStorage.getItem('user');

    if (!user) {
      return {
        id: '',
        email: '',
        company: {
          id: '',
          name: '',
          document: '',
        },
      };
    }

    const {
      email = '',
      user: { id = '' } = {},
      merchant: {
        company_name: company = '',
        id: companyId = '',
        document_number = '',
      } = {},
    } = JSON.parse(user);

    return {
      id,
      email: encrypt(email),
      company: {
        id: encrypt(companyId),
        name: company,
        document: encrypt(document_number),
      },
    };
  }
}
