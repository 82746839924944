import { Action } from '@ngrx/store';
import { ISettings } from '../../../models/settings/settings';

export const SETTINGS_ADD = '@settings/add';

export class AddSettings implements Action {
  readonly type = SETTINGS_ADD;

  constructor(public payload: ISettings) {
  }
}

export type Actions = AddSettings;
