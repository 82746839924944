import * as SellActions from './actions';
import { ISell } from '../../../models/sell/sell';

const INITIAL_STATE: ISell = {
  user: {},
  value: null,
  success: null,
  procedures: [],
};

export function sellReducer(
  state: ISell = INITIAL_STATE,
  action: SellActions.Actions
): any {
  switch (action.type) {
    case SellActions.SELL_ADD:
      return (state = {
        ...state,
        ...action.payload,
      });
    case SellActions.SELL_REMOVE:
      return {
        ...INITIAL_STATE,
        ...action.payload,
      };
    default:
      return state;
  }
}
