import { Server } from 'miragejs';
import loginResponse from '../../cypress/fixtures/login-response.json';

export function makeServer({ environment = 'development' } = {}): Server {
  return new Server({
    environment,

    routes(): void {
      this.urlPrefix = 'https://dev-platform.valesaudesempre.com.br';
      this.namespace = 'api';

      this.post('/v1/auth/merchants/login', () => loginResponse);
    },
  });
}
