import { Action } from '@ngrx/store';
import { IUser } from '../../../models/user/user';

export const USER_ADD = '@user/add';
export const USER_UPDATE = '@user/update';
export const USER_ADD_TOKEN = '@user/add_token';
export const USER_REMOVE = '@user/remove';

export class AddUser implements Action {
  readonly type = USER_ADD;

  constructor(public payload: IUser) {}
}

export class UpdateUser implements Action {
  readonly type = USER_UPDATE;

  constructor(public payload: IUser) {}
}

export class AddUserToken implements Action {
  readonly type = USER_ADD_TOKEN;

  constructor(public payload: string) {}
}

export class RemoveUser implements Action {
  readonly type = USER_REMOVE;

  constructor() {}
}

export type Actions = AddUser | AddUserToken | RemoveUser | UpdateUser;
