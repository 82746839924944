import * as SettingsActions from './actions';
import { ISettings } from '../../../models/settings/settings';

const INITIAL_STATE: ISettings = { phones: {}, merchant: {} };

export function settingsReducer(
  state: ISettings = INITIAL_STATE,
  action: SettingsActions.Actions
): ISettings {
  switch (action.type) {
    case SettingsActions.SETTINGS_ADD:
      return (state = action.payload);
    default:
      return state;
  }
}
