<h1 mat-dialog-title>Estamos de cara nova :)</h1>

<div mat-dialog-content>
  <p>
    Pensando em melhorar a sua experiência em nosso sistema, lançamos a nova
    Área do Credenciado Vale Saúde Sempre.
  </p>

  <br />

  <p>
    Caso não lembre sua senha, clique
    <a
      routerLink="/login/recuperar-senha"
      mat-dialog-close=""
      title="esqueceu sua senha"
      >aqui</a
    >.
  </p>

  <br />

  <p>
    Em caso de dúvidas entre em contato conosco pelo
    <a href="tel:3003-0256"> <strong>3003-0256 opção 3</strong></a
    >.
  </p>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    class="dd-m-button-secondary big full"
    mat-dialog-close=""
    data-testid="button-ok"
  >
    Ok
  </button>
</div>
